import { Cookies } from '../adapter/cookies'
import { CmpImpl } from './cmp.impl'
import { LoggingService } from '../utils/logging-service'
import { initGlobal } from './bootstrap/init-global'
import { initNexus } from './bootstrap/init-nexus'
import { ConsentProviderAdapter } from '../adapter/consent-provider.adapter'
import { ReceiptArchiver } from './receipt-archiver'
import { CmpIabTcfApiService } from './iab-tcf/cmp-iab-tcf-api.service'
import { cookie } from '@otto-ec/global-resources/cookie'
import { AppsAdapter } from '../adapter/apps.adapter'
import { logger } from '@otto-ec/global-resources/debug'
import { Cmp } from './cmp.type'

const GVL_PATH = import.meta.env.BASE_URL
try {
  const appsAdapter = new AppsAdapter()
  const loggingService = new LoggingService()

  const consentProviderAdapter = new ConsentProviderAdapter(appsAdapter)
  const cmpIabTcfApiService = new CmpIabTcfApiService(GVL_PATH, loggingService)
  const receiptArchiver = new ReceiptArchiver(loggingService)

  const cookies = new Cookies(cookie)

  const cmp: Cmp = new CmpImpl(
    consentProviderAdapter,
    cmpIabTcfApiService,
    receiptArchiver,
    cookies,
    loggingService
  )
  initNexus(cmp)
  initGlobal(cmp)
} catch (e) {
  new LoggingService().logError(e)
  logger('eprivacy.cmp.index').error('Error initializing cmp', e)
}
